import { defineNuxtRouteMiddleware } from 'nuxt/app';
import type { RouteLocationNormalized } from 'vue-router';

import { PRODUCT_BUILD_PAGE_URL } from '@/constants/url.const';
import { useBuildStore } from '@/stores/build.store';
import { redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  const buildId = to.params.buildId as string;
  const buildStore = useBuildStore();

  const success = await buildStore.getBuildDetail({ buildId });
  if (!success) {
    return await redirectTo(PRODUCT_BUILD_PAGE_URL);
  }

  return true;
});
